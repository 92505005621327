// src/features/auth/LoginForm.js

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCredentials } from '../slices/authSlice'; 
import LoginIcon from './img/login.svg';  

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function LoginForm() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/users/login`, formData);
      dispatch(setCredentials({
        user: response.data.user, 
        token: response.data.token
      }));
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error.response?.data || 'Unable to log in');
    }
  };

  const inputStyle = {
    padding: '16px',
    borderColor: '#333333',
    fontSize: '1rem',
    borderRadius: '5px',
    marginBottom: '20px'
  };

  return (
    <Container fluid>
      <Row className="align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <Col md={6} style={{ padding: '50px', maxWidth: '600px', margin: 'auto' }}>
          <h1>Log In</h1>
          <p style={{ marginTop: '30px', marginBottom: '30px' }}>
            Access your account to continue using <strong>Sage Analytics</strong>.
          </p>
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Button variant="primary" type="submit" style={{ marginTop: '20px', width: '100%', borderRadius: '50px', paddingTop: '15px', paddingBottom: '15px', backgroundColor: '#1AAB9B', border: 'none', fontSize: '1.2rem'}}>
              Log In
            </Button>
          </Form>
        </Col>
        <Col md={6} className="d-none d-md-flex justify-content-center align-items-center">
          <img src={LoginIcon} alt="Log In" style={{ maxWidth: '100%', height: 'auto' }} />
        </Col>
      </Row>
    </Container>
  );
}

export default LoginForm;