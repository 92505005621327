import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const RealTimeUsers = () => {
    const [data, setData] = useState([]);
    const d3Container = useRef(null);

    useEffect(() => {
        // Generate initial data based on exponential growth
        const initialData = Array.from({ length: 180 }, (_, index) => ({
            time: new Date(Date.now() - (180 - index) * 1000 * 3600 * 24),
            value: Math.exp(index / 30)  // Exponential growth formula
        }));
        setData(initialData);

        const updateData = () => {
            setData(currentData => {
                const lastPoint = currentData[currentData.length - 1];
                const newPoint = {
                    time: new Date(lastPoint.time.getTime() + 1000 * 3600 * 24),  // One day per second for simulation
                    value: lastPoint.value * 1.1  // Increase by 10%
                };
                return [...currentData.slice(1), newPoint];  // Move the window
            });
        };

        const intervalId = setInterval(updateData, 1000);  // Update every second

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (data.length > 0 && d3Container.current) {
            const svg = d3.select(d3Container.current);
            svg.selectAll("*").remove(); // Clear SVG content

            const margin = { top: 20, right: 20, bottom: 30, left: 40 },
                width = d3Container.current.clientWidth - margin.left - margin.right,
                height = 300 - margin.top - margin.bottom;

            svg.attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`);

            const x = d3.scaleTime()
                .domain(d3.extent(data, d => d.time))
                .range([0, width]);

            const y = d3.scaleLinear()
                .domain([0, d3.max(data, d => d.value)])
                .range([height, 0]);

            const line = d3.line()
                .x(d => x(d.time))
                .y(d => y(d.value));

            const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

            g.append("g")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x));

            g.append("g")
                .call(d3.axisLeft(y));

            g.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", "#1AAB9B")
                .attr("stroke-width", 2)
                .attr("d", line);
        }
    }, [data]);

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg
                ref={d3Container}
                style={{ width: '100%', height: '300px' }}
            />
        </div>
    );
};

export default RealTimeUsers;