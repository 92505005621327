// client/src/slices/websiteSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchWebsite = createAsyncThunk('website/fetchWebsite', async (_, { getState }) => {
    const { token } = getState().auth;
    if (!token) {
        throw new Error('JWT token is not set');
    }
    const response = await axios.get(`${API_BASE_URL}/api/websites/`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
});

export const addWebsite = createAsyncThunk('website/addWebsite', async (websiteData, { getState }) => {
    const { token } = getState().auth;
    const response = await axios.post(`${API_BASE_URL}/api/websites/add`, websiteData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
});

export const updateWebsite = createAsyncThunk('website/updateWebsite', async ({ websiteId, ...websiteData }, { getState }) => {
    const { token } = getState().auth;
    const response = await axios.put(`${API_BASE_URL}/api/websites/update/${websiteId}`, websiteData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
});

const websiteSlice = createSlice({
    name: 'website',
    initialState: {
        website: null,
        apiKey: null,
        status: 'idle',
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWebsite.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWebsite.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.website = action.payload;
                state.apiKey = action.payload.apiKey;
            })
            .addCase(fetchWebsite.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addWebsite.fulfilled, (state, action) => {
                state.website = action.payload;
                state.apiKey = action.payload.apiKey;
            })
            .addCase(updateWebsite.fulfilled, (state, action) => {
                state.website = action.payload;
                state.apiKey = action.payload.apiKey;
            });
    }
});

export default websiteSlice.reducer;