import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/authSlice'; 

function NavigationBar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        navigate('/'); 
    };

    return (
        <Navbar bg="light" expand="lg" className="mb-3">
            <Container fluid style={{ '--bs-gutter-x': '0px' }}>
                <Navbar.Brand className="d-flex align-items-center" style={{ height: '100%' }}>
                    <NavLink to="/dashboard">
                        <img
                            src="logo.png"
                            width="100"
                            height="20"
                            className="d-inline-block align-center"
                            alt="Sage Analytics Logo"
                        />
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to="/dashboard">Dashboard</Nav.Link>
                        <Nav.Link href="#reports">Reports</Nav.Link>
                        <NavDropdown title="Analytics" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Traffic</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Conversions</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">User Behavior</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Advanced Settings</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                    <NavDropdown title="Profile" id="nav-dropdown" align="end">
                        <NavDropdown.Item as={NavLink} to="/profile">Your Profile</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="/settings">Settings</NavDropdown.Item>
                        <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
                    </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;