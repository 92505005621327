// src/components/Header.js

import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Header() {
  const linkStyle = {
    color: '#000000',
    fontSize: '1.5rem',
    textDecoration: 'none',
    marginRight: '20px'  
  };

  const loginStyle = {
    color: '#1AAB9B',
    fontSize: '1.5rem',
    textDecoration: 'none',
    marginRight: '20px'  
  };

  const signUp = {
    color: '#f8f9fa',
    backgroundColor: '#1AAB9B',
    fontSize: '1.5rem',
    border: 'none',
    borderRadius: '40px',
    padding: '10px 20px',
    display: 'inline-block',
    lineHeight: 'normal',
    textDecoration: 'none',
    marginRight: '0px'  
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>
          <NavLink to="/">
            <img
              src="logo.png"
              width="200"
              height="40"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink to="/" style={linkStyle} className="nav-link">Home</NavLink>
            <NavLink to="/product" style={linkStyle} className="nav-link">Product</NavLink>
            <NavLink to="/pricing" style={linkStyle} className="nav-link">Pricing</NavLink>
            <NavLink to="/company" style={linkStyle} className="nav-link">Company</NavLink>
            <NavLink to="/login" style={loginStyle} className="nav-link">Login</NavLink>
            <NavLink to="/register" style={signUp} className="nav-link">Sign Up</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;