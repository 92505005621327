import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const ConversionRateChart = ({ data }) => {
    const d3Container = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (data && d3Container.current && containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const svg = d3.select(d3Container.current);
            svg.selectAll("*").remove();

            const width = containerWidth;
            const height = 300;
            const radius = Math.min(width, height) / 2 - 15;

            svg.attr("viewBox", `0 0 ${width} ${height}`);

            const arc = d3.arc().outerRadius(radius).innerRadius(radius - 40);
            const pie = d3.pie().sort(null).value(d => d.value);

            const g = svg.append("g").attr("transform", `translate(${width / 2}, ${height / 2})`);

            const colorMap = {
                'Converted': '#FDC134',
                'Not Converted': '#1AAB9B'
            };

            const total = d3.sum(data, d => d.value); 

            const slice = g.selectAll(".arc")
                .data(pie(data))
                .enter().append("g")
                .attr("class", "arc");

            slice.append("path")
                .attr("d", arc)
                .attr("stroke", d => colorMap[d.data.label])
                .attr("fill", d => colorMap[d.data.label]) 
                .attr("stroke-width", 5);

            // Legend
            const legend = svg.append("g")
                .attr("transform", `translate(0, ${height - 40})`);

            Object.entries(colorMap).forEach(([key, value], index) => {
                const legendRow = legend.append("g")
                    .attr("transform", `translate(0, ${index * 20})`);

                legendRow.append("rect")
                    .attr("width", 10)
                    .attr("height", 10)
                    .attr("fill", value);

                legendRow.append("text")
                    .attr("x", 20)
                    .attr("y", 10)
                    .text(`${key} (${(data.find(d => d.label === key).value / total * 100).toFixed(1)}%)`) 
                    .style("font-size", "12px")
                    .attr("text-anchor", "start");
            });
        }
    }, [data]);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg
                className="d3-component"
                ref={d3Container}
                style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
        </div>
    );
}

export default ConversionRateChart;