import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import SignupIcon from './img/signup.svg';  

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function RegisterForm() {
    const [emailEntered, setEmailEntered] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        password: ''
    });

    const navigate = useNavigate();

    const handleEmailSubmit = (event) => {
        event.preventDefault();
        setEmailEntered(true);  
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleFinalSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/api/users/register`, {
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName,
                companyName: formData.companyName,
                password: formData.password
            });
            alert('User registered successfully. Please login.');
            navigate('/login');
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Registration failed:', error.response.data);
            } else {
                console.error('Registration failed:', error.message);
            }
        }
    };    

    const inputStyle = {
        padding: '16px',
        borderColor: '#333333',
        fontSize: '1rem',
        borderRadius: '5px',
        marginBottom: '20px'
    };

    return (
        <Container fluid>
            <Row className="align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
                <Col md={6} style={{ padding: '50px', maxWidth: '600px', margin: 'auto' }}>
                    <h1>Create an account</h1>
                    <p style={{ marginTop: '30px', marginBottom: '30px' }}>
                        Begin harnessing the power of <strong>Sage Analytics</strong>, the premier platform for comprehensive website insights, in less than a minute.
                    </p>
                    <Form onSubmit={emailEntered ? handleFinalSubmit : handleEmailSubmit}>
                        {emailEntered && (
                            <>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="formBasicFirstName">
                                            <Form.Label>First Name:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="firstName"
                                                placeholder="Enter first name"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="formBasicLastName">
                                            <Form.Label>Last Name:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="lastName"
                                                placeholder="Enter last name"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="formBasicCompanyName">
                                            <Form.Label>Company Name:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="companyName"
                                                placeholder="Enter company name"
                                                value={formData.companyName}
                                                onChange={handleChange}
                                                required
                                                style={inputStyle}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={inputStyle}
                            />
                        </Form.Group>
                        {emailEntered && (
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    style={inputStyle}
                                />
                            </Form.Group>
                        )}
                        <Button variant="primary" type="submit" style={{ marginTop: '20px', width: '100%', borderRadius: '50px', paddingTop: '15px', paddingBottom: '15px', backgroundColor: '#1AAB9B', border: 'none', fontSize: '1.2rem' }}>
                            {emailEntered ? 'Create Account' : 'Continue'}
                        </Button>
                    </Form>
                </Col>
                <Col md={6} className="d-none d-md-flex justify-content-center align-items-center">
                    <img src={SignupIcon} alt="Sign Up" style={{ maxWidth: '100%', height: 'auto' }} />
                </Col>
            </Row>
        </Container>
    );
}

export default RegisterForm;