import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const BounceRateChart = ({ data }) => {
    const d3Container = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (data && d3Container.current && containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const svg = d3.select(d3Container.current);
            svg.selectAll("*").remove(); // Clear previous SVG content

            const margin = { top: 20, right: 30, bottom: 40, left: 50 },
                width = containerWidth - margin.left - margin.right,
                height = 300 - margin.top - margin.bottom; // Fixed height for consistency

            svg.attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`);

            const chart = svg.append("g")
                .attr("transform", `translate(${margin.left}, ${margin.top})`);

            const x = d3.scaleBand()
                .range([0, width])
                .domain(data.map(d => d.day))
                .padding(0.1);

            const y = d3.scaleLinear()
                .domain([0, 100]) 
                .range([height, 0]);

            chart.append("g")
                .attr("transform", `translate(0, ${height})`)
                .call(d3.axisBottom(x));

            chart.append("g")
                .call(d3.axisLeft(y));

            data.forEach(d => {
                const barHeight = height - y(d.bounceRate);
                const barX = x(d.day);
                const barY = y(d.bounceRate);

                // Draw the sides
                chart.append("line")
                    .attr("x1", barX)
                    .attr("y1", barY)
                    .attr("x2", barX)
                    .attr("y2", barY + barHeight)
                    .attr("stroke", "#1AAB9B")
                    .attr("stroke-width", 3);

                chart.append("line")
                    .attr("x1", barX + x.bandwidth())
                    .attr("y1", barY)
                    .attr("x2", barX + x.bandwidth())
                    .attr("y2", barY + barHeight)
                    .attr("stroke", "#1AAB9B")
                    .attr("stroke-width", 3);

                // Draw the top
                chart.append("line")
                    .attr("x1", barX)
                    .attr("y1", barY)
                    .attr("x2", barX + x.bandwidth())
                    .attr("y2", barY)
                    .attr("stroke", "#1AAB9B")
                    .attr("stroke-width", 3);

                // Add text label on top of the bar
                chart.append("text")
                    .attr("x", barX + x.bandwidth() / 2)
                    .attr("y", barY - 5)
                    .attr("text-anchor", "middle")
                    .text(d.bounceRate + "%")
                    .attr("font-size", "12px")
                    .attr("fill", "#333");
            });
        }
    }, [data, containerRef.current]);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg
                className="d3-component"
                ref={d3Container}
                style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
        </div>
    );
}

export default BounceRateChart;