// src/components/Dashboard/Settings.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Form, Button, Card, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { FaClipboard } from 'react-icons/fa';
import NavigationBar from './NavigationBar';
import { fetchUserProfile, updateUserProfile } from '../../slices/userSlice';
import { fetchWebsite, updateWebsite } from '../../slices/websiteSlice';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Settings = () => {
    const { user, token } = useSelector(state => state.auth);
    const { userProfile } = useSelector(state => state.user);
    const { website } = useSelector(state => state.website);
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [isWebsiteEditing, setIsWebsiteEditing] = useState(false);
    const [userData, setUserData] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: user.companyName,
        email: user.email,
        notifications: true
    });
    const [websiteData, setWebsiteData] = useState({
        url: '',
        name: '',
        description: ''
    });
    const [apiKey, setApiKey] = useState('');

    useEffect(() => {
        if (!userProfile) {
            dispatch(fetchUserProfile());
        }
        dispatch(fetchWebsite());
    }, [dispatch, userProfile]);

    useEffect(() => {
        if (userProfile) {
            setUserData({
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                companyName: userProfile.companyName,
                email: userProfile.email,
                notifications: true
            });
        }
    }, [userProfile]);

    useEffect(() => {
        if (website) {
            setWebsiteData({
                url: website.url,
                name: website.name,
                description: website.description
            });
            if (website.apiKey) {
                setApiKey(website.apiKey);
            }
        }
    }, [website]);

    const handleEditToggle = () => setIsEditing(!isEditing);
    const handleWebsiteEditToggle = () => setIsWebsiteEditing(!isWebsiteEditing);

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setUserData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleWebsiteChange = (event) => {
        const { name, value } = event.target;
        setWebsiteData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSave = () => {
        setIsEditing(false);
        dispatch(updateUserProfile(userData));
    };

    const handleWebsiteSave = () => {
        setIsWebsiteEditing(false);
        dispatch(updateWebsite({ websiteId: website._id, ...websiteData }));
    };

    const handleGenerateApiKey = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/api/websites/generate-api-key/${website._id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setApiKey(response.data.apiKey);
        } catch (error) {
            console.error('Error generating API key:', error);
        }
    };

    return (
        <Container fluid>
            <NavigationBar />
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-center">Settings</Card.Title>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5>User Settings</h5>
                                <Button 
                                    style={{ backgroundColor: '#1AAB9B', borderColor: '#1AAB9B' }} 
                                    onClick={handleEditToggle}
                                >
                                    {isEditing ? 'Cancel' : 'Edit'}
                                </Button>
                            </div>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        First Name:
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="text"
                                            readOnly={!isEditing}
                                            name="firstName"
                                            value={userData.firstName}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Last Name:
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="text"
                                            readOnly={!isEditing}
                                            name="lastName"
                                            value={userData.lastName}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Company Name:
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="text"
                                            readOnly={!isEditing}
                                            name="companyName"
                                            value={userData.companyName}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Email:
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="email"
                                            readOnly={!isEditing}
                                            name="email"
                                            value={userData.email}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                {isEditing && (
                                    <Button 
                                        style={{ backgroundColor: '#1AAB9B', borderColor: '#1AAB9B', width: '100%' }} 
                                        onClick={handleSave}
                                    >
                                        Save Changes
                                    </Button>
                                )}
                            </Form>
                            <hr />
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5>Website Settings</h5>
                                <Button 
                                    style={{ backgroundColor: '#1AAB9B', borderColor: '#1AAB9B' }} 
                                    onClick={handleWebsiteEditToggle}
                                >
                                    {isWebsiteEditing ? 'Cancel' : 'Edit'}
                                </Button>
                            </div>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Website URL:
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="text"
                                            readOnly={!isWebsiteEditing}
                                            name="url"
                                            value={websiteData.url}
                                            onChange={handleWebsiteChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Website Name:
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="text"
                                            readOnly={!isWebsiteEditing}
                                            name="name"
                                            value={websiteData.name}
                                            onChange={handleWebsiteChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        Description:
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            type="text"
                                            readOnly={!isWebsiteEditing}
                                            name="description"
                                            value={websiteData.description}
                                            onChange={handleWebsiteChange}
                                        />
                                    </Col>
                                </Form.Group>
                                {isWebsiteEditing && (
                                    <Button 
                                        style={{ backgroundColor: '#1AAB9B', borderColor: '#1AAB9B', width: '100%' }} 
                                        onClick={handleWebsiteSave}
                                        className="mb-3"
                                    >
                                        Save Changes
                                    </Button>
                                )}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>
                                        API Key:
                                    </Form.Label>
                                    <Col sm={10}>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                readOnly={true}
                                                value={apiKey}
                                            />
                                            <Button 
                                                variant="secondary" 
                                                onClick={() => navigator.clipboard.writeText(apiKey)}
                                                style={{ backgroundColor: '#1AAB9B', borderColor: '#1AAB9B' }}
                                            >
                                                <FaClipboard />
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Button 
                                    style={{ backgroundColor: '#1AAB9B', borderColor: '#1AAB9B', width: '100%' }} 
                                    onClick={handleGenerateApiKey}
                                    className="mb-3"
                                >
                                    Generate API Key
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Settings;