// client/src/slices/analyticsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log('API_BASE_URL:', API_BASE_URL);

export const fetchVisitData = createAsyncThunk('analytics/fetchVisitData', async (_, { getState }) => {
    const { token } = getState().auth;
    const { website } = getState().website;

    if (!website || !website._id || !website.apiKey) {
        throw new Error('Website ID or API key is not set');
    }

    console.log('Token:', token);
    console.log('Website ID:', website._id);
    console.log('API Key:', website.apiKey);

    const response = await axios.get(`${API_BASE_URL}/api/analytics/visits?websiteId=${website._id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': website.apiKey 
        }
    });
    console.log('Received visit data:', response.data);
    return response.data;
});

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState: {
        visitData: [],
        status: 'idle',
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVisitData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVisitData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.visitData = action.payload.map(item => ({
                    date: new Date(item.date),
                    value: item.visits
                }));
            })
            .addCase(fetchVisitData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                console.error('Failed to fetch visit data:', action.error.message);
            });
    }
});

export default analyticsSlice.reducer;