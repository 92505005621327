// src/App.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LoginForm from './Authentification/LoginForm';
import RegisterForm from './Authentification/RegisterForm';
import Header from './components/Website/Header';
import HomePage from './components/Website/HomePage';
import Dashboard from './components/Dashboard/Dashboard';
import UserProfile from './components/Dashboard/UserProfile';
import Settings from './components/Dashboard/Settings';
import ProtectedRoute from './components/Dashboard/ProtectedRoute';

function HeaderWrapper() {
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  const hideHeaderRoutes = ['/dashboard']; 
  const showHeader = !user && !hideHeaderRoutes.includes(location.pathname);

  return showHeader ? <Header /> : null;
}

function App() {
  return (
    <Router>
      <HeaderWrapper />
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={ <ProtectedRoute><Dashboard /></ProtectedRoute> } />
        <Route path="/profile" element={ <ProtectedRoute><UserProfile /></ProtectedRoute>} />
        <Route path="/settings" element={ <ProtectedRoute><Settings /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}

export default App;