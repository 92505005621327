// client/src/components/Dashboard/TrafficChart.js

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const TrafficChart = ({ data }) => {
    const d3Container = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (data && d3Container.current && containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const svg = d3.select(d3Container.current);
            svg.selectAll("*").remove();

            // Set dimensions and margins for the graph
            const margin = { top: 20, right: 30, bottom: 40, left: 40 },
                width = containerWidth - margin.left - margin.right,
                height = 300 - margin.top - margin.bottom;

            // Configure the SVG to use a viewBox for responsive sizing
            svg.attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
                .attr("preserveAspectRatio", "xMidYMid meet");

            // Initialize the chart group within the SVG
            const chart = svg.append("g")
                .attr("transform", `translate(${margin.left}, ${margin.top})`);

            // Ensure the data dates are Date objects in UTC and sort them
            const parsedData = data.map(d => ({
                ...d,
                date: new Date(d.date + 'T00:00:00Z'), 
                visits: d.visits
            })).sort((a, b) => a.date - b.date);

            // Debugging: Log parsed data to verify
            console.log("Parsed Data:", parsedData);

            // Get the current week's start and end dates in UTC
            const today = new Date();
            const startOfWeek = new Date(Date.UTC(
                today.getUTCFullYear(),
                today.getUTCMonth(),
                today.getUTCDate() - today.getUTCDay()
            ));
            const endOfWeek = new Date(Date.UTC(
                today.getUTCFullYear(),
                today.getUTCMonth(),
                today.getUTCDate() - today.getUTCDay() + 6
            ));

            // Add missing days to data
            const completeData = [];
            for (let day = new Date(startOfWeek); day <= endOfWeek; day.setUTCDate(day.getUTCDate() + 1)) {
                const found = parsedData.find(d => d.date.getTime() === day.getTime());
                completeData.push(found ? found : { date: new Date(day), visits: 0 });
            }

            // Debugging: Log complete data to verify
            console.log("Complete Data:", completeData);

            // Create scales
            const x = d3.scaleTime()
                .domain([startOfWeek, endOfWeek])
                .range([0, width]);

            const y = d3.scaleLinear()
                .domain([0, d3.max(completeData, d => d.visits) || 1])
                .range([height, 0]);

            // Add X axis
            chart.append("g")
                .attr("transform", `translate(0, ${height})`)
                .call(d3.axisBottom(x).ticks(d3.utcDay.every(1)).tickFormat(d3.utcFormat('%m/%d')));

            // Add Y axis
            chart.append("g")
                .call(d3.axisLeft(y));

            // Draw the line
            const line = d3.line()
                .x(d => x(d.date))
                .y(d => y(d.visits));

            chart.append("path")
                .datum(completeData)
                .attr("fill", "none")
                .attr("stroke", "#1AAB9B")
                .attr("stroke-width", 2)
                .attr("d", line);

            // Add circles
            chart.selectAll("circle")
                .data(completeData)
                .enter()
                .append("circle")
                .attr("cx", d => x(d.date))
                .attr("cy", d => y(d.visits))
                .attr("r", 5)
                .attr("fill", "#1AAB9B")
                .on("mouseover", (event, d) => {
                    const svgRect = d3Container.current.getBoundingClientRect();
                    const tooltip = d3.select(containerRef.current)
                        .append("div")
                        .style("position", "absolute")
                        .style("visibility", "visible")
                        .style("padding", "10px")
                        .style("background", "white")
                        .style("border", "1px solid #ccc")
                        .style("border-radius", "5px")
                        .text(`Visits: ${d.visits}`)
                        .style("left", `${event.clientX - svgRect.left}px`)
                        .style("top", `${event.clientY - svgRect.top - 40}px`);
                })
                .on("mouseout", () => {
                    d3.select(containerRef.current).selectAll("div").remove();
                });
        }
    }, [data, containerRef.current]);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg
                className="d3-component"
                ref={d3Container}
                style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
        </div>
    );
};

export default TrafficChart;