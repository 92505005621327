// src/components/Dashboard/UserProfile.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import { fetchWebsite, addWebsite } from '../../slices/websiteSlice';

const UserProfile = () => {
    const { user } = useSelector(state => state.auth);
    const { website, apiKey } = useSelector(state => state.website);
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [websiteData, setWebsiteData] = useState({
        url: '',
        name: '',
        description: ''
    });

    useEffect(() => {
        dispatch(fetchWebsite());
    }, [dispatch]);

    const handleWebsiteChange = (event) => {
        const { name, value } = event.target;
        setWebsiteData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleWebsiteSubmit = async (event) => {
        event.preventDefault();
        dispatch(addWebsite(websiteData));
        setShowModal(false);
    };

    return (
        <Container fluid>
            <NavigationBar />
            <Row className="justify-content-center mt-4">
                <Col md={8}>
                    <Card className="shadow-sm p-4">
                        <Card.Body>
                            <Card.Title className="text-center mb-4">Your Profile</Card.Title>
                            <Row className="mb-3">
                                <Col sm={4} className="text-muted"><strong>First Name:</strong></Col>
                                <Col sm={8}>{user.firstName}</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={4} className="text-muted"><strong>Last Name:</strong></Col>
                                <Col sm={8}>{user.lastName}</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={4} className="text-muted"><strong>Company Name:</strong></Col>
                                <Col sm={8}>{user.companyName}</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={4} className="text-muted"><strong>Email:</strong></Col>
                                <Col sm={8}>{user.email}</Col>
                            </Row>
                            <Row className="mb-4">
                                <Col sm={4} className="text-muted"><strong>Member Since:</strong></Col>
                                <Col sm={8}>{new Date(user.memberSince).toLocaleDateString()}</Col>
                            </Row>
                            {website ? (
                                <div className="mt-4">
                                    <h5 className="text-center mb-4">Your Website</h5>
                                    <Row className="mb-3">
                                        <Col sm={4} className="text-muted"><strong>URL:</strong></Col>
                                        <Col sm={8}>{website.url}</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col sm={4} className="text-muted"><strong>Name:</strong></Col>
                                        <Col sm={8}>{website.name}</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col sm={4} className="text-muted"><strong>Description:</strong></Col>
                                        <Col sm={8}>{website.description}</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col sm={4} className="text-muted"><strong>API Key:</strong></Col>
                                        <Col sm={8}>{apiKey}</Col>
                                    </Row>
                                </div>
                            ) : (
                                <Button 
                                    variant="success" 
                                    onClick={() => setShowModal(true)} 
                                    className="mt-3 w-100"
                                >
                                    Add Website
                                </Button>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Website</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleWebsiteSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>URL</Form.Label>
                            <Form.Control
                                type="text"
                                name="url"
                                value={websiteData.url}
                                onChange={handleWebsiteChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={websiteData.name}
                                onChange={handleWebsiteChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={websiteData.description}
                                onChange={handleWebsiteChange}
                            />
                        </Form.Group>
                        <Button 
                            variant="primary" 
                            type="submit"
                            className="w-100"
                        >
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={() => setShowModal(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default UserProfile;