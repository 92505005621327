import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import realTimeAnalyticsIcon from './analytics.svg';
import userBehaviorTrackingIcon from './tracking.svg';
import conversionTrackingIcon from './goals.svg';
import segmentationIcon from './segmentation.svg';

function Features() {
  const features = [
    {
      title: "Real-Time Analytics",
      description: "This feature delivers instantaneous data about visitor activities, allowing website owners to observe user behavior as it unfolds in real time. With Real-Time Analytics, you can track the number of active users, identify which pages they are visiting, understand the sources of traffic including social media, direct visits, and referrals, and detect any immediate shifts in user behavior. This capability is invaluable for assessing the effectiveness of marketing campaigns, product launches, or any site changes, enabling quick decision-making based on live data.",
      icon: realTimeAnalyticsIcon,
      right: true
    },
    {
      title: "User Behavior Tracking",
      description: "Gain deep and actionable insights into how visitors interact with your website. User Behavior Tracking goes beyond simple page views and bounce rates to analyze every click, mouse movement, scroll action, and time spent on each page. This feature also incorporates advanced tools like heat maps and session recordings, which visualize the areas of your site that attract the most attention and engagement. By understanding these user interactions, you can optimize your website's layout, improve content placement, and ultimately enhance user experience to boost engagement and retention.",
      icon: userBehaviorTrackingIcon,
      right: false
    },
    {
      title: "Conversion Tracking and Goal Completion",
      description: "A vital tool for any results-driven website, Conversion Tracking and Goal Completion helps you measure how effectively your site achieves its key performance indicators (KPIs). Set up and monitor specific user actions that align with business goals, such as completing a purchase, signing up for a newsletter, or engaging with a video. This feature also includes funnel analysis, which identifies at which stages potential customers drop off during the conversion process. Insights gained here can be used to refine the user journey, eliminate barriers to conversion, and improve overall site performance.",
      icon: conversionTrackingIcon,
      right: true
    },
    {
      title: "Segmentation and Personalization Tools",
      description: "Segmentation and Personalization Tools empower website owners to classify users into distinct groups based on a variety of factors including behavior patterns, demographic data, device usage, and acquisition channels. This feature enables the delivery of tailored content and experiences to different user segments, enhancing engagement and satisfaction. Personalization extends to customizing offers, messages, and content, ensuring that each visitor receives a relevant and engaging experience. Such targeted approaches not only improve user engagement but also increase the effectiveness of marketing campaigns and boost conversion rates.",
      icon: segmentationIcon,
      right: false
    }
  ];

  const svgStyle = {
    height: '250px',  
    width: 'auto',
  };

  return (
    <Container>
      <h1 className="text-center my-4" style={{ color: '#333333' }}>Our Features</h1> {/* Title for the features section */}
      {features.map((feature, index) => (
        <Row key={index} className="align-items-center my-5 flex-column-reverse flex-md-row">
          <Col md={6} className={`text-center ${feature.right ? 'order-md-2' : ''}`}>
            <img src={feature.icon} alt="Feature Icon" style={svgStyle} />
          </Col>
          <Col md={6} className={`${feature.right ? 'text-left' : 'text-right'}`}>
            <h2 style={{color: '#1AAB9B'}}>{feature.title}</h2>
            <p style={{ color: '#333333' }}>{feature.description}</p>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default Features;