// client/src/components/LandingPage.js

import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import VaultIcon from './vault.svg';

function Landing() {
  const metrics = [
    { value: "+120%", description: "Organic Search Traffic" },
    { value: "+65%", description: "Revenue Growth" },
    { value: "+250%", description: "Subscriber Growth" },
    { value: "+25%", description: "Conversions" }
  ];

  return (
    <Container fluid style={{ paddingTop: '2rem', width: '100%', background: 'linear-gradient(162deg, rgba(26,171,155,0.75) 0%, rgba(248,249,250,0.5) 50%, rgba(253,193,52,0.75) 100%)' }}>
      <Row>
        <Col md={6} className="d-flex align-items-center justify-content-center" style={{ textAlign: 'left', paddingLeft: '3rem' }}>
          <div>
            <h1 style={{ color: '#333333', fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '20px' }}>Unlock Your Website's Potential</h1>
            <p style={{ color: '#333333', fontSize: '1.5rem', marginBottom: '30px' }}>Transform Data into Actionable Insights</p>
            <NavLink to="/register">
              <Button variant="primary" style={{ backgroundColor: '#1AAB9B', border: 'none', borderRadius: '55px', padding: '10px 30px', width: '200px', height: '48px', fontSize: '19px' }}>Sign Up Here</Button>
            </NavLink>
          </div>
        </Col>
        <Col md={6} className="text-center">
          <img src={VaultIcon} alt="Vault" style={{ width: 'auto', height: '400px', maxWidth: '400px' }} />
        </Col>
      </Row>
      <Row className="text-center mb-3" style={{marginTop: '-20px'}}>
        <Col xs={12}>
          <h2>Our customers get results</h2>
        </Col>
      </Row>
      <Row className="text-center pb-3">
        {metrics.map((metric, index) => (
          <Col key={index} xs={12} sm={6} md={3}>
            <div>
              <h3 style={{ fontSize: '2rem', color: '#1AAB9B' }}>{metric.value}</h3>
              <p style={{ fontSize: '1rem', color: '#333333' }}>{metric.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Landing;