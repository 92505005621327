import React from 'react';
import { Container, ListGroup, Row, Col } from 'react-bootstrap';

function Value() {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <div style={{ padding: '60px 30px', background: '#ffffff', borderRadius: '8px', boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)', margin: '50px 0' }}>
            <h2 className="text-center" style={{ color: '#1AAB9B', marginBottom: '40px', fontWeight: 'bold' }}>Boost your website performance in three easy steps</h2>
            <ListGroup variant="flush">
              {['Sign up for Sage\'s free trial. No credit card required. Start exploring the full suite of tools immediately.', 
                'Add tracking code to your website or mobile app. Simple copy-paste integration that takes only minutes.', 
                'Start improving your website. Use our comprehensive analytics to make informed decisions and optimize your site\'s performance.'].map((item, index) => (
                <ListGroup.Item key={index} style={{ backgroundColor: 'transparent', borderColor: 'transparent', display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                  <div style={{ fontSize: '3rem', color: '#1AAB9B', marginRight: '20px', flexShrink: 0 }}>{index + 1}.</div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <strong style={{ marginBottom: '5px' }}>{item.split('.')[0]}.</strong>
                    <span>{item.split('.').slice(1).join('.')}</span>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Value;