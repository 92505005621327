// src/components/HomePage.js

import React from 'react';
import Landing from './Landing/LandingPage';
import Features from './features/Features';
import Value from './value';

function HomePage() {
  return (
    <div style={{backgroundColor: '#f8f9fa'}}>
      <Landing />
      <Value />
      <Features />
    </div>
  );
}

export default HomePage;