// client/components/Dashboard/Dashboard.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import TrafficChart from './TrafficChart';
import RealTimeUsers from './RealTimeUsers';
import ConversionRateChart from './ConversionRateChart';
import BounceRateChart from './BounceRateChart';
import NavigationBar from './NavigationBar';
import { fetchVisitData } from '../../slices/analyticsSlice';
import { fetchWebsite } from '../../slices/websiteSlice';

function Dashboard() {
    const dispatch = useDispatch();
    const { visitData, status } = useSelector(state => state.analytics);
    const { user } = useSelector(state => state.auth);
    const { website, status: websiteStatus } = useSelector(state => state.website);

    useEffect(() => {
        if (user && !website) {
            console.log('User is available:', user);
            console.log('Fetching website data');
            dispatch(fetchWebsite());
        }
    }, [dispatch, user, website]);

    useEffect(() => {
        if (user && website && website._id) {
            console.log('Website is available:', website);
            console.log('Dispatching fetchVisitData');
            dispatch(fetchVisitData());
        }
    }, [dispatch, user, website]);

    const conversionData = [
        { label: 'Converted', value: 300 },
        { label: 'Not Converted', value: 700 }
    ];

    const bounceData = [
        { day: 'Sun', bounceRate: 60 },
        { day: 'Mon', bounceRate: 70 },
        { day: 'Tue', bounceRate: 30 },
        { day: 'Wed', bounceRate: 50 },
        { day: 'Thu', bounceRate: 90 },
        { day: 'Fri', bounceRate: 20 },
        { day: 'Sat', bounceRate: 90 }
    ];

    const chartComponents = [
        { title: "Site Visits", Component: TrafficChart, data: visitData },
        { title: "Bounce Rate", Component: BounceRateChart, data: bounceData },
        { title: "Conversion Rate", Component: ConversionRateChart, data: conversionData },
        { title: "Real-Time Users", Component: RealTimeUsers, data: null }
    ];

    return (
        <Container fluid>
            <NavigationBar />
            <Row className="my-3">
                <Col md={12}><h2>Dashboard Overview</h2></Col>
            </Row>
            <Row>
                {status === 'loading' && <p>Loading...</p>}
                {status === 'failed' && <p>Error loading data</p>}
                {status === 'succeeded' && chartComponents.map((item, idx) => (
                    <Col key={idx} md={12} lg={6} className="mb-4">
                        <Card className="h-100" style={{ height: '400px', margin: '0.5rem' }}>
                            <Card.Body className="d-flex flex-column justify-content-around align-items-center">
                                <Card.Title>{item.title}</Card.Title>
                                <item.Component data={item.data} />
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Dashboard;