// src/app/store.js

import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import websiteReducer from '../slices/websiteSlice';
import userReducer from '../slices/userSlice';
import analyticsReducer from '../slices/analyticsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    website: websiteReducer,
    user: userReducer,
    analytics: analyticsReducer,
  },
});
