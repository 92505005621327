// src/slices/userSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUserProfile = createAsyncThunk('user/fetchUserProfile', async (_, { getState }) => {
    const { token } = getState().auth;
    const response = await axios.get(`${API_BASE_URL}/api/users/profile`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
});

export const updateUserProfile = createAsyncThunk('user/updateUserProfile', async (userData, { getState }) => {
    const { token } = getState().auth;
    const response = await axios.put(`${API_BASE_URL}/api/users/profile`, userData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userProfile: null,
        status: 'idle',
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userProfile = action.payload;
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                state.userProfile = action.payload;
            });
    }
});

export default userSlice.reducer;